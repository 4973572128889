<template>
  <svg
    width="160"
    height="45"
    viewBox="0 0 160 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2144 9.04675V15.463L28.8688 22.1177L22.2144 28.7707V35.1886L35.2848 22.1177L22.2144 9.04675Z"
      fill="white"
    />
    <path
      d="M22.1168 37.8174L6.416 22.1176L22.1168 6.41625V0L0 22.1176L22.1168 44.2353V37.8174Z"
      fill="white"
    />
    <path
      d="M46.4656 23.8058L46.4912 31.5357H43.8704V12.3958H46.4912L46.4816 21.0009L46.4656 23.8058Z"
      fill="white"
    />
    <path
      d="M65.2848 12.3958V31.5357H62.7168V12.3958H65.2848Z"
      fill="white"
    />
    <path
      d="M88.792 12.3958V32.1709H88.712L74.656 17.9016L74.7088 31.5357H72.1152V11.8134H72.248L86.2784 26.2411L86.2256 12.3958H88.792Z"
      fill="white"
    />
    <path
      d="M107.891 31.1644C106.646 31.5516 105.496 31.7468 104.437 31.7468C102.441 31.7468 100.686 31.3372 99.1678 30.5164C97.6494 29.6955 96.4814 28.5691 95.6606 27.1402C94.8398 25.7114 94.4302 24.1065 94.4302 22.3225C94.4302 20.2392 94.8718 18.4343 95.7534 16.9079C96.635 15.3814 97.827 14.2117 99.3278 13.3989C100.827 12.5877 102.469 12.1813 104.251 12.1813C105.328 12.1813 106.342 12.3093 107.296 12.5653C108.249 12.8213 109.104 13.1605 109.864 13.5845L109.816 16.239C109.251 15.9206 107.741 15.3462 106.886 15.1078C106.03 14.8694 105.259 14.7494 104.571 14.7494C103.088 14.7494 101.777 15.0502 100.64 15.6486C99.5022 16.2502 98.6238 17.1047 98.0062 18.2167C97.3886 19.3287 97.0798 20.6264 97.0798 22.108C97.0798 23.4841 97.3886 24.7113 98.0062 25.7882C98.6238 26.865 99.4974 27.6987 100.627 28.2891C101.757 28.8811 103.045 29.1771 104.493 29.1771C105.163 29.1771 105.816 29.1099 106.451 28.9771C107.086 28.8459 107.616 28.6555 108.04 28.4091V24.6793V24.6233L108.128 22.1609H110.633V29.8907C110.048 30.3532 109.134 30.7772 107.891 31.1644Z"
      fill="white"
    />
    <path
      d="M126.872 13.9574C128.045 14.9974 128.632 16.4551 128.632 18.3256C128.632 19.4024 128.416 20.4073 127.984 21.3433C127.551 22.2793 126.855 23.0378 125.893 23.6202C124.93 24.2026 123.717 24.4938 122.253 24.4938H119.421V31.5357H116.853V12.3958H121.989C124.071 12.3958 125.698 12.9158 126.872 13.9574ZM124.488 21.4217C125.061 21.0345 125.472 20.5609 125.719 20.0056C125.965 19.4504 126.09 18.9064 126.09 18.3784C126.09 17.4599 125.789 16.6439 125.189 15.9287C124.589 15.2151 123.655 14.8566 122.383 14.8566H119.418V22.0041H122.25C123.17 22.0057 123.915 21.8105 124.488 21.4217Z"
      fill="white"
    />
    <path
      d="M136.493 12.3958V31.5357H133.925V12.3958H136.493Z"
      fill="white"
    />
    <path
      d="M160 12.3958V32.1709H159.92L145.864 17.9016L145.917 31.5357H143.323V11.8134H143.456L157.486 26.2411L157.434 12.3958H160Z"
      fill="white"
    />
    <path
      d="M57.0016 29.2075L49.944 22.1288L57.0176 15.055V11.5892L46.4912 22.1176L57.0176 32.6444L57.0016 29.2075Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppLogoSvg',
}
</script>
