<template>
  <video
    autoplay
    loop
    class="login-video"
    muted
    playsinline=""
    data-wf-ignore="true"
    data-object-fit="cover"
  >
    <source
      src="./auth-right-video.mp4"
      type="video/mp4"
    >
    <source
      src="./auth-right-video.webm"
      type="video/webm"
    >
  </video>
</template>

<script>
export default {
  name: 'AppVideo',
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
