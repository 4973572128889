<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 auth-logo-container signup-bg-color">
      <!-- App logo-->
      <b-link class="app-logo">
        <app-logo-svg />
      </b-link>
      <!-- /App logo-->

      <!-- Left Content-->
      <b-col
        id="auth-left-container"
        lg="7"
        class="d-none d-lg-flex align-items-center"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <!-- Left Video-->
          <app-video />
        </div>
      </b-col>
      <!-- /Left Content-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex justify-content-center auth-bg auth-reg signup-portion signup-bg-color"
      >
        <div
          v-if="!isActive(CHOOSE_ROLE)"
          class="cursor-pointer font-montserrat d-flex back-button"
          @click="$router.go(-1)"
        >
          <left-caret />
          <span>BACK</span>
        </div>
        <div
          class="
            d-flex
            flex-column
            font-montserrat
          "
        >
          <div class="w-100 signup-welcome-section">
            <div class="text-center">
              <p class="header-text">
                Adventure starts here 🚀
              </p>
              <p class="sub-header">
                Create Your Account
              </p>
            </div>
            <div class="carousel-section">
              <div
                v-for="slide in slides"
                :key="slide"
                class="carousel-icon"
                :class="{ active: isActive(slide) }"
              />
            </div>
          </div>
          <!-- Body -->
          <div class="body-child-routes">
            <!-- child routes -->
            <router-view :route-query-params="routeQueryParams" />
          </div>
        </div>
        <div class="font-montserrat have-an-account">
          Already have an account?
          <b-link
            to="/login"
            class="cursor-pointer hyperlink-text-style"
          >
            Sign in
          </b-link>
        </div>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import { BRow, BLink, BCol } from 'bootstrap-vue'
import AppLogoSvg from '@/@core/assets/svg-components/AppLogoSvg.vue'
import pages, { AUTH_REGISTER_CHILDREN, AUTH_REGISTER } from '@/router/routes/pages'
import LeftCaret from '@/@core/assets/svg-components/LeftCaret.vue'
import AppVideo from '@/assets/videos/AppVideo.vue'

export default {
  name: 'Signup',
  components: {
    BRow,
    BLink,
    BCol,
    AppLogoSvg,
    LeftCaret,
    AppVideo,
  },
  data() {
    return {
      slides: [],
      CHOOSE_ROLE: AUTH_REGISTER_CHILDREN.CHOOSE_ROLE,
      routeQueryParams: {},
    }
  },
  created() {
    this.setRoutes()
    this.setRouteQueryParams()
  },
  methods: {
    setRoutes() {
      const registerRoute = pages.find(routeObj => routeObj.name === AUTH_REGISTER)
      this.slides = registerRoute.children
        .map(child => child.name)
        .filter(name => name !== AUTH_REGISTER)
    },
    setRouteQueryParams() {
      if (this.$route.query) {
        this.routeQueryParams = this.$route.query
      }
    },
    isActive(routeName) {
      return this.$route.name === routeName
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
@import '~@core/scss/vue/pages/signup/signup-page.scss';
@import '~@core/scss/vue/pages/signup/kp-signup.scss';
</style>
